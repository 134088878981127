import React from 'react';
import loadable from '@loadable/component';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IconFacebook2 from 'components/icons/IconFacebook2';
import IconHouzz from 'components/icons/IconHouzz';
import IconInstagram from 'components/icons/IconInstagram';
import IconLinkedIn from 'components/icons/IconLinkedIn';
import IconPinterest from 'components/icons/IconPinterest';
import IconTwitter from 'components/icons/IconTwitter';
import IconYoutube from 'components/icons/IconYoutube';
import currentYear from 'commons/currentYear';
import useUserData from 'data-hooks/useUserData';
import ConsumerMobileFooterMenu from './ConsumerMobileFooterMenu';

const ContactBtns = loadable(() => import('./components/ContactBtns'));

const ConsumerNewFooter = () => {
  const { isTradeUser } = useUserData();

  return (
    <div className="flex flex-col max-w-[1400px] pt-4 px-[3.333vw] pb-10 mx-auto my-0 min-[1500px]:px-0 min-[1500px]:py-4 print:hidden [&_a]:no-underline delayed-display">
      <div className="[border-bottom:1px_solid_#f1f1f1] px-[3.333vw] py-8 mt-0 mx-0 mb-8 md:px-0 md:py-8 max-[767px]:[border-bottom:0] max-[767px]:pt-8 max-[767px]:px-0 max-[767px]:pb-0">
        <div className="mx-auto my-0 [&_a:focus-visible]:focus-visible:outline-offset-4 [&_a:focus-visible]:focus-visible:outline-brand flex justify-between max-md:flex-col-reverse">
          <div className="text-lg flex-[2_1] font-bold [&_ul_a]:text-sm [&_ul_a]:[border-bottom:1px_solid_transparent] [&_ul_a]:font-normal [&_ul_a]:text-gray [&_ul_a]:no-underline [&_ul_a:hover]:[border-bottom:1px_solid_#424242] [&_ul_a:hover]:text-gray [&_li]:pl-8 [&_li]:list-none [&_li]:mt-[.2rem] [&_li]:mr-[.4rem] [&_li]:mb-[.2rem] [&_li]:ml-0 max-[767px]:w-full max-[767px]:mt-8 max-[767px]:mx-0 max-[767px]:mb-0">
            <div className="flex [&_ul]:ml-0 [&_ul]:mb-0 max-[767px]:flex-col">
              <div className="w-[120px] lg:w-[180px] min-[1440px]:w-[220px] max-[767px]:hidden">
                <ul>
                  <li className="text-gray !mb-[.6rem] [&_a]:!text-gray">
                    About Us
                  </li>
                  <li>
                    <Link to="/about-us/">Story</Link>
                  </li>
                  <li>
                    <Link to="/the-factory/">Workshop</Link>
                  </li>
                  <li>
                    <Link to="/reviews/">Reviews</Link>
                  </li>
                  <li>
                    <Link to="/sustainability/">Sustainability</Link>
                  </li>
                  <li>
                    <Link to="/press/">Press</Link>
                  </li>
                  <li>
                    <Link to="/careers/">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact-us/">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="w-[120px] lg:w-[180px] min-[1440px]:w-[220px] max-[767px]:hidden">
                <ul>
                  <li className="text-gray !mb-[.6rem] [&_a]:!text-gray">
                    Resources
                  </li>
                  <li>
                    <Link to="/shipping-and-delivery/">Shipping</Link>
                  </li>
                  <li>
                    <Link to="/returns/">Returns</Link>
                  </li>
                  <li>
                    <Link to="/warranty/">Warranty</Link>
                  </li>
                  <li>
                    <Link to="/product-financing/">Financing</Link>
                  </li>
                  <li>
                    <Link to="/showrooms/">Showrooms</Link>
                  </li>
                  <li>
                    <Link to="/product-safety/">Product Safety</Link>
                  </li>
                  <li>
                    <a href="https://help.joybird.com">FAQs</a>
                  </li>
                </ul>
              </div>
              <div className="w-[120px] lg:w-[180px] min-[1440px]:w-[220px] max-[767px]:hidden">
                <ul>
                  <li className="text-gray !mb-[.6rem] [&_a]:!text-gray">
                    Discover
                  </li>
                  <li>
                    <Link to="/customer-photos/">Customer Gallery</Link>
                  </li>
                  <li>
                    <Link to="/swatches/">Shop by Color</Link>
                  </li>
                  <li>
                    <Link to="/collaborations/">Collaborations</Link>
                  </li>
                  <li>
                    <Link to="/swatches/">Materials</Link>
                  </li>
                  <li>
                    <Link to="/business/">Trade & Business</Link>
                  </li>
                  <li>
                    <a href="https://joybird.com/blog/">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="w-[320px] lg:w-[350px] min-[1440px]:w-[400px] max-[767px]:hidden">
                <ul>
                  <li className="text-gray !mb-[.6rem] [&_a]:!text-gray">
                    Locations
                  </li>
                  <div className="flex flex-row">
                    <ul className="flex-1">
                      <li>
                        <Link to="/showrooms/austin/">Austin, TX</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/brooklyn/">Brooklyn, NY</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/chicago/">Chicago, IL</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/denver/">Denver, CO</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/dtla/">Los Angeles, CA</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/manhattan/">Manhattan, NY</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/philadelphia/">
                          Philadelphia, PA
                        </Link>
                      </li>
                    </ul>
                    <ul className="flex-1 md:[border-left:1px_solid_#f1f1f1]">
                      <li>
                        <Link to="/showrooms/portland/">Portland, OR</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/san-francisco/">
                          San Francisco, CA
                        </Link>
                      </li>
                      <li>
                        <Link to="/showrooms/seattle/">Seattle, WA</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/dc/">Washington DC</Link>
                      </li>
                      <li>
                        <Link to="/showrooms/los-angeles/">
                          West Hollywood, CA
                        </Link>
                      </li>
                      <li>
                        <Link to="/showrooms/virtual/">Virtual Showroom</Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
            <ConsumerMobileFooterMenu />
          </div>

          <div className="flex-[1_1] bg-white text-center md:px-0 md:py-8 md:[border-left:1px_solid_#f1f1f1] max-[767px]:w-auto">
            <h2 className="text-lg font-bold mt-0 mx-0 mb-[.6rem]">
              Need a Hand?
            </h2>
            <p className="text-sm text-gray lg:px-4 lg:py-0 flex flex-col content-center">
              {isTradeUser ? (
                <>
                  <span
                    className="flex flex-col content-center mb-3"
                    aria-label="Trade user hours of business"
                  >
                    <span>Mon-Fri: 7:00 am - 4:00 pm PST</span>
                    <span>Sat-Sun: Closed</span>
                  </span>

                  <span
                    className="flex flex-col content-center"
                    aria-label="Trade user telephone number"
                  >
                    <span className="md:hidden">
                      Call Us:{' '}
                      <a className="text-brand" href="tel:+18552084414">
                        (855) 208-4414
                      </a>
                    </span>

                    <span className="hidden md:inline">
                      Call Us: (855) 208-4414
                    </span>
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="flex flex-col content-center mb-3"
                    aria-label="Hours of business"
                  >
                    <span>Mon-Fri: 6:00 am - 5:00 pm PST</span>
                    <span>Sat-Sun: 8:00 am - 4:00 pm PST</span>
                  </span>

                  <span
                    className="flex flex-col content-center"
                    aria-label="Telephone numbers"
                  >
                    <span className="md:hidden">
                      Call Us:{' '}
                      <a className="text-brand" href="tel:+18882820842">
                        (888) 282-0842
                      </a>
                    </span>

                    <span className="md:hidden">
                      Text Us:{' '}
                      <a className="text-brand" href="sms:+12136006313">
                        (213) 600-6313
                      </a>
                    </span>

                    <span className="hidden md:inline">
                      Call Us: (888) 282-0842
                    </span>

                    <span className="hidden md:inline">
                      Text Us: (213) 600-6313
                    </span>
                  </span>
                </>
              )}
            </p>
            <ContactBtns />
          </div>
        </div>
      </div>
      <div className="consumer__secondary">
        <div className="mx-auto my-0 [&_a:focus-visible]:focus-visible:outline-offset-4 [&_a:focus-visible]:focus-visible:outline-brand flex justify-between max-md:flex-col-reverse">
          <div className="flex flex-row max-[767px]:flex-col">
            <div
              className={classNames(
                'flex flex-row font-bold text-gray text-sm mr-9 max-[767px]:flex-col max-[767px]:items-center max-[767px]:m-0 max-[767px]:font-normal max-[767px]:text-[#a5a7a9] last:max-[767px]:mt-2',
                '[&_a]:text-sm [&_a]:mr-9 [&_a]:font-normal [&_a]:no-underline max-[767px]:[&_a]:m-[.4rem] [&_a]:hover:underline'
              )}
            >
              &#169; {currentYear} Joybird, All rights reserved.
            </div>

            <div
              className={classNames(
                'flex flex-row font-bold text-gray text-sm mr-9 max-[767px]:flex-col max-[767px]:items-center max-[767px]:m-0 max-[767px]:font-normal max-[767px]:text-[#a5a7a9] last:max-[767px]:mt-2',
                '[&_a]:text-sm [&_a]:mr-9 [&_a]:font-normal [&_a]:no-underline max-[767px]:[&_a]:m-[.4rem] [&_a:hover]:underline'
              )}
            >
              <Link to="/Terms-of-use/">Terms of Use</Link>
              <Link to="/Privacy-policy/">Privacy Notice</Link>
              <Link to="/Sitemap/">Sitemap</Link>
              <Link to="/accessibility/">Accessibility</Link>
            </div>
          </div>
          <div className="flex flex-row justify-center text-right mb-6 lg:mb-0 [&_a]:[filter:invert(49%)_sepia(11%)_saturate(14%)_hue-rotate(325deg)_brightness(99%)_contrast(97%)] gap-6 flex-wrap">
            <a
              href="https://www.facebook.com/wearejoybird"
              target="_blank"
              title="Facebook"
              rel="noreferrer"
            >
              <IconFacebook2 height={17} />
            </a>
            <a
              href="https://twitter.com/wearejoybird"
              target="_blank"
              title="Twitter"
              rel="noreferrer"
            >
              <IconTwitter height={17} />
            </a>
            <a
              href="https://www.pinterest.com/joybird/"
              target="_blank"
              title="Pinterest"
              rel="noreferrer"
            >
              <IconPinterest height={17} />
            </a>
            <a
              href="https://www.instagram.com/joybird/"
              target="_blank"
              title="Instagram"
              rel="noreferrer"
            >
              <IconInstagram height={17} />
            </a>
            <a
              href="https://www.youtube.com/c/joybird"
              target="_blank"
              title="YouTube"
              rel="noreferrer"
            >
              <IconYoutube height={17} />
            </a>
            <a
              href="https://www.houzz.com/pro/wearejoybird/joybird-furniture"
              target="_blank"
              title="Houzz"
              rel="noreferrer"
            >
              <IconHouzz height={17} />
            </a>
            <a
              href="https://www.linkedin.com/company/joybird"
              target="_blank"
              title="Linkedin"
              rel="noreferrer"
            >
              <IconLinkedIn height={17} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerNewFooter;
